import React from 'react'
import Layout from '../components/Layout'
import styled from 'styled-components'
import Seo from '../components/SEO/PageSeo'

const Legal = () => {
  return (
    <Layout>
      <Seo />
      <Wrapper>
        <h1>Informations légales</h1>
        <div className="underline"></div>
        <h2>Responsable</h2>
        Jay Fischer
        <br />
        Lipper Weg 121
        <br />
        45772 Marl
        <br />
        Allemagne
        <h3>Email</h3>
        kontakt@inkfs.com
        <br />
        <h3>Téléphone</h3>
        00 49 178 8 137 148
        <h2>Avis général et informations obligatoires</h2>
        <h3>Désignation de l'entité responsable</h3>
        L'entité responsable du traitement des données sur ce site web est : Ink
        and Feather Design Studios Jay Fischer Lipper Weg 121 45772 Marl
        Allemagne. L'organe responsable décide seul ou conjointement avec
        d'autres des finalités et des moyens du traitement des données à
        caractère personnel (par exemple, noms, coordonnées ou similaires).
        <h3>Révocation de votre consentement au traitement des données</h3>.
        Certains traitements de données ne sont possibles qu'avec votre
        consentement exprès. Une révocation de votre consentement déjà donné est
        possible à tout moment. Une communication informelle par e-mail est
        suffisante pour la révocation. La légalité du traitement des données
        effectué jusqu'à la révocation n'est pas affectée par la révocation.
        <h3>
          Droit de déposer une plainte auprès de l'autorité de contrôle
          compétente
        </h3>
        . En tant que personne concernée, vous avez le droit de porter plainte
        auprès de l'autorité de contrôle compétente en cas de violation de la
        protection des données. L'autorité de surveillance compétente en matière
        de protection des données est le commissaire à la protection des données
        de l'État fédéral dans lequel se trouve le siège social de notre
        société. Le lien suivant fournit une liste des délégués à la protection
        des données et leurs coordonnées :
        https://www.bfdi.bund.de/DE/Infothek/Anschriften_Links/anschriften_links-node.html.
        <h3>Droit à la portabilité des données</h3>
        Vous avez le droit de faire remettre à vous ou à des tiers les données
        que nous traitons automatiquement sur la base de votre consentement ou
        en exécution d'un contrat. Les données seront fournies dans un format
        lisible. Si vous demandez le transfert direct des données à un autre
        responsable, cela ne sera fait que dans la mesure où cela est
        techniquement possible.
        <h3>
          Droit à l'information, à la rectification, au blocage, à l'effacement
        </h3>
        Dans le cadre des dispositions légales applicables, vous avez à tout
        moment le droit d'obtenir gratuitement des informations sur vos données
        personnelles stockées, l'origine des données, leurs destinataires et la
        finalité du traitement des données et, le cas échéant, un droit de
        rectification, de blocage ou de suppression de ces données. À cet égard,
        et également pour d'autres questions sur le sujet des données
        personnelles, vous pouvez nous contacter à tout moment via les options
        de contact indiquées dans les mentions légales.
        <h3>Cryptage SSL ou TLS</h3>
        Pour des raisons de sécurité et pour protéger la transmission de
        contenus confidentiels que vous nous envoyez en tant qu'exploitant du
        site, notre site web utilise un cryptage SSL ou TLS. Cela signifie que
        les données que vous transmettez via ce site web ne peuvent pas être
        lues par des tiers. Vous pouvez reconnaître une connexion cryptée à la
        ligne d'adresse "https://" de votre navigateur et au symbole du cadenas
        dans la ligne du navigateur.
        <h3>Fichiers journaux du serveur</h3>
        Dans les fichiers journaux du serveur, le fournisseur du site web
        collecte et stocke automatiquement les informations que votre navigateur
        nous transmet automatiquement. Il s'agit de :
        <ul>
          <li>Page visitée sur notre domaine</li>.
          <li>Date et heure de la requête du serveur</li>.
          <li>Type de navigateur et version du navigateur</li>.
          <li>Système d'exploitation utilisé</li>.<li>L'URL du référent</li>
          <li>Nom d'hôte de l'ordinateur accédant</li>.<li>Adresse IP</li>
        </ul>
        Il n'y a pas de fusion de ces données avec d'autres sources de données.
        Le traitement des données est basé sur l'art. 6 (1) lit. b DSGVO, qui
        autorise le traitement des données pour l'exécution d'un contrat ou de
        mesures précontractuelles.
        <h3>Formulaire de contact et inscription à la newsletter</h3>
        Les données transmises via le formulaire de contact seront stockées, y
        compris vos données de contact, afin de pouvoir traiter votre demande ou
        d'être disponible pour des questions de suivi. Ces données ne seront pas
        transmises sans votre consentement. Le traitement des données saisies
        dans le formulaire de contact se fonde exclusivement sur votre
        consentement (art. 6, al. 1, lit. a DSGVO). Une révocation de votre
        consentement déjà donné est possible à tout moment. Une communication
        informelle par e-mail est suffisante pour la révocation. La légalité des
        opérations de traitement des données effectuées jusqu'à la révocation
        n'est pas affectée par la révocation. Les données transmises via le
        formulaire de contact ou via l'inscription à la newsletter restent chez
        nous jusqu'à ce que vous nous demandiez de les supprimer, que vous
        révoquiez votre consentement à leur stockage ou qu'il n'y ait plus de
        nécessité de stocker les données. Les dispositions légales obligatoires
        - en particulier les délais de conservation - ne sont pas affectées.
        <br />
        <br />
        Source : Configurateur de protection des données de
        mein-datenschutzbeauftragter.de.
      </Wrapper>
    </Layout>
  )
}

export default Legal

const Wrapper = styled.section`
  width: 85vw;
  max-width: 1100px;
  margin: 0 auto;
  margin-top: 5rem;
  margin-bottom: 4rem;
  h1,
  h2,
  h3,
  h4 {
    margin-top: 2rem;
  }
  ul {
    margin: 1rem;
  }
`
